
<template>
  <v-dialog v-model="active" :width="screenWidth" persistent>
    <div class="content-card-dialog">
      <v-btn class="content-card-button-close" @click="closeDialog" icon>
        <v-icon color="#000" size="16px"> mdi-close </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins">{{title }}</p>
      <div class="display-flex align-items-center justify-content-center">
        <img width="70px" src="@/assets/images/delete_icon.png" alt="" />
      </div>
      <p class="content-card-txt-description poppins mt-5" v-html="description" />
      <div class="content-buttons">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div class="
                  display-flex
                  align-items-center
                  justify-content-flex-start
                ">
                <v-btn @click="closeDialog" class="button-secondary poppins" elevation="0" width="100%">
                  Cancelar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn @click="deleteItem" :loading="bLoading" class="button-primary poppins" elevation="0"
                  width="100%">
                  Eliminar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LayoutClientsAdd",
  props: {
    oOptions: {
      type: Object,
    },
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      active: false,
      title: "",
      description: "",

    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    deleteItem: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").
        then((resp) => {
          if (this.getPermissionsActionsGlobal() || this.getWithoutPermissionsActionsGlobal(this.oOptions.title)) {
            DB.delete(`${URI}${this.oOptions.api}`, {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {},
            })
              .then((response) => {
                this.bLoading = false;
                this.closeDialog();
                this.mixSuccess(response.data.message);
                if (this.oOptions.bReturnView) {
                  this.$router.go(-1);
                } else {
                  this.$store.commit("refresher", true);
                }
                this.$store
                  .dispatch("getNotificationGlobal")
                  .then((resp) => {
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((error) => {
                this.bLoading = false;
                this.mixError(error.response.data.message, error.response.status);
              });
          } else {
            this.bLoading = false;
            this.mixError(this.$store.state.sMessageErrorAccess, 0);
            this.closeDialog();
            this.$store.commit("refresher", true);

          }
        }).catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
    },
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.screenWidth = 420 + "px";
      } else {
        this.screenWidth = 100 + "%";
      }
    },
    closeDialog: function () {
      let options = {
        active: false,
        id: "",
        api: "",
        title: "",
        description: "",
      };
      this.$emit("setOptions", options);
    },
  },
  watch: {
    oOptions() {
      if (this.oOptions.active) {
        this.active = this.oOptions.active;
        this.title = this.oOptions.title;
        this.description = this.oOptions.description;
      }else{
        this.active = false;
        this.title = "";
        this.description = "";
      }
    }
  }
};
</script>

<style scoped>
.content-inputs {
  margin-top: 50px;
}

.content-buttons {
  margin-top: 25px;
}
</style>